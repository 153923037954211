import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import App from './App'; // Assuming your main component is App
import { extendTheme } from "@chakra-ui/react"
const theme = extendTheme({
  fonts: {
    body: "Source Code Pro, sans-serif",
    heading: "Source Code Pro, serif",
    mono: "Source Code Pro, monospace",
  },
})
ReactDOM.render(
  <React.StrictMode>
    <ChakraProvider  theme={theme}>
      <App />
    </ChakraProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

