import { Stack, Container, Box, Text, Heading } from '@chakra-ui/react'

function NexetIntro() {
  return (
    <Box bg={'gray.800'} position={'relative'}>
      <Container maxW={'7xl'} zIndex={10} position={'relative'}>
        <Stack direction={{ base: 'column', lg: 'row' }}>
          <Stack
            flex={1}
            color={'gray.400'}
            justify={{ lg: 'center' }}
            py={{ base: 4, md: 10, xl: 20 }}>
            <Box mb={{ base: 8, md: 20 }}>
              <Text
                fontFamily={'heading'}
                fontWeight={700}
                textTransform={'uppercase'}
                mb={3}
                fontSize={'xl'}
                color={'gray.500'}>
                Our Solutions
              </Text>
              <Heading color={'white'} mb={5} fontSize={{ base: '3xl', md: '5xl' }}>
                Tailored to meet your needs
              </Heading>

              {services.map((service) => (
                <Box key={service.title} mb={10}>
                  <Heading color={'white'} mb={3} fontSize={{ base: '2xl', md: '3xl' }}>
                    {service.title}
                  </Heading>
                  <Text fontSize={'xl'} color={'gray.400'}>
                    {service.content}
                  </Text>
                </Box>
              ))}
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Box>
  )
}

const services = [
    {
        "title": "Campus Educational Network and Metropolitan Area Network Establishment",
        "content": "Comprehensive networking solutions are provided for educational institutions and metropolitan areas, aiming to establish a reliable and secure network infrastructure. Network designs leverage the Open Shortest Path First (OSPF) protocol for efficient routing within a network, and the Border Gateway Protocol (BGP) for routing and reachability information between networks. Services encompass network design, installation, and maintenance, all tailored to specific client requirements."
    },
    {
        "title": "Data Migration Solutions",
        "content": "Data migration solutions cater to businesses and organizations relocating their data. The process covers initial data cleaning and standardization, high-speed data transfer utilizing Dense Wavelength Division Multiplexing (DWDM) technology, and data verification and optimization at the new data center. The goal is to ensure a seamless data migration process with minimal disruption to business operations."
    },
    {
        "title": "Advanced Multi-modal Latency Detection",
        "content": "The advanced multi-modal latency detection system is designed to detect and address latency issues. Utilizing International Ethernet Private Line (IEPL) technology, it offers end-to-end data transmission services bypassing the public internet, ensuring low-latency data transfer. Configurations are customizable to meet latency detection needs across different application scenarios."
    }
]

export default NexetIntro;
