import React from 'react';

import './App.css'; // 引入CSS样式
import HomePage from './pages/Home';

function App() {
  return (
    <HomePage />
  );
}

export default App;
