import React from 'react';
import NexetHeader from './Header';
import Hero from './Hero';
import NexetIntro from './Intro';
import NexetServices from './Services';
import NexetFooter from './Footer';


function HomePage() {
    return (
      <div className="App">
  
          {/* 首页部分 */}
            <NexetHeader />
            <Hero />
            <NexetServices />
            <NexetIntro />
            <NexetFooter />
            {/* Hero Part */}
          </div>
      );
}

export default HomePage;