import React from 'react';
import { Box, Text, useColorModeValue } from '@chakra-ui/react';

const Hero = () => {
  const textColor = useColorModeValue('white', 'gray.800');

  return (
    <Box position="relative" height="50vh" width="100%">
      <video
        autoPlay
        loop
        muted
        playsInline
        style={{
          position: "absolute",
          width: "100%",
          left: "50%",
          top: "50%",
          height: "100%",
          objectFit: "cover",
          transform: "translate(-50%, -50%)",
          zIndex: "-1",
        }}
        src="https://nex.et/bg.mp4" // Make sure to replace this with the actual path to your video
      ></video>
      <Box
        position="absolute"
        width="100%"
        height="100%"
        backgroundColor="rgba(0, 0, 0, 0.4)" // This is the video overlay. Adjust the rgba values to get the color and opacity you want.
      ></Box>
      <Box
        position="absolute"
        top="50%"
        left="50%"
        transform="translate(-50%, -50%)"
        color={textColor}
        textAlign="center"
      >
        <Text fontSize={{ base: "2xl", md: "4xl", lg: "5xl" }} fontWeight="bold">
          Welcome to Nexet
        </Text>
        <Text fontSize={{ base: "xl", md: "xl", lg: "2xl" }} >
          Pioneers in Advanced Networking Solutions
        </Text>
      </Box>
    </Box>
  );
};

export default Hero;
