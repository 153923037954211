import {
    Box,
    Button,
    Container,
    Flex,
    Heading,
    Icon,
    Stack,
    Text,
    useColorModeValue,
  } from '@chakra-ui/react'
  import {
    FcAbout,
    FcServices,
    FcGlobe,
    FcClock,
    FcProcess,
    FcMindMap,
  } from 'react-icons/fc'
  
  const Card = ({ heading, description, icon }) => {
    return (
      <Box
        maxW={{ base: 'full', md: '275px' }}
        w={'full'}
        borderWidth="1px"
        borderRadius="lg"
        overflow="hidden"
        p={5}
        bg={useColorModeValue('gray.200', 'gray.700')}>
        <Stack align={'start'} spacing={2}>
          <Flex
            w={16}
            h={16}
            align={'center'}
            justify={'center'}
            color={'white'}
            rounded={'full'}
            bg={useColorModeValue('white', 'blue.300')}>
            {icon}
          </Flex>
          <Box mt={2}>
            <Heading size="md">{heading}</Heading>
            <Text mt={1} fontSize={'sm'}>
              {description}
            </Text>
          </Box>
          <Button variant={'link'} colorScheme={'blue'} size={'sm'}>
            Learn more
          </Button>
        </Stack>
      </Box>
    )
  }
  
  export default function NexetServices() {
    return (
      <Box p={4} bg={useColorModeValue('gray.800', 'gray.50')} m={0}>
        <Container maxW={'5xl'} mt={12}>
          <Flex flexWrap="wrap" gridGap={6} justify="center">
            <Card
              heading={'Cloud Service'}
              icon={<Icon as={FcAbout} w={10} h={10} />}
              description={
                'Offering diverse Cloud Solutions across Infrastructure as a Service (IaaS), Platform as a Service (PaaS), and Software as a Service (SaaS) applications.'
              }
            />
            <Card
              heading={'Managed Service'}
              icon={<Icon as={FcServices} w={10} h={10} />}
              description={
                'Specializing in IT Outsourcing, we provide comprehensive 24/7 Remote Management and Monitoring Services.'
              }
            />
            <Card
              heading={'System Integration'}
              icon={<Icon as={FcProcess} w={10} h={10} />}
              description={
                'Our professional services encompass System Integration on Enterprise IT platforms.'
              }
            />
            <Card
              heading={'Hyper Fibre Cross the Sea'}
              icon={<Icon as={FcGlobe} w={10} h={10} />}
              description={
                'At the forefront of ultra-high-speed fiber-optic communication, designed to connect continents with minimal latency.'
              }
            />
            <Card
              heading={'High-Precision Trading Clock'}
              icon={<Icon as={FcClock} w={10} h={10} />}
              description={
                'A revolutionary time synchronization system tailored for the financial trading sector.'
              }
            />
            <Card
              heading={'AI Driven Solutions'}
              icon={<Icon as={FcMindMap} w={10} h={10} />}
              description={
                'Leveraging the power of AI to provide cutting-edge solutions, driving efficiency and innovation in your business.'
              }
            />
          </Flex>
        </Container>
      </Box>
    )
  }
  